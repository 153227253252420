<template>
    <div>
        <v-bottom-sheet v-model="shareVisible" inset max-width="800px">
            <v-sheet class="text-center" :height="'220px'" @click="setShareVisible(false)" style="background: white;">
                <v-row class="mx-auto"
                    justify="center">
                    
                    <span>
                        <v-btn class="ma-6" small fab dark color="primary" @click="copyToClipboard">
                            <v-icon medium dark color="white">mdi-content-copy</v-icon>
                        </v-btn>
                        <br/>
                        <h5 class="mt-n2 network-txt primary--text">העתק</h5>  
                    </span>
                    
                    <ShareNetwork
                        v-for="platform in platforms"
                        :network="platform.network"
                        :key="platform.network"
                        :url="shareState.share.url"
                        :title="shareState.share.title"
                        :description="shareState.share.description"
                        :quote="shareState.share.quote"
                        :hashtags="shareState.share.hashtags"
                        :twitterUser="shareState.share.twitterUser">

                        <span>
                            <v-btn class="ma-6" small fab dark color="primary" @click="setShareVisible(false)">
                                <v-icon medium dark color="white">{{ platform.icon }}</v-icon>
                            </v-btn>
                            <br/>
                            <h5 class="mt-n2 network-txt primary--text">{{ platform.name }}</h5>
                        </span>
                        
                        
                    </ShareNetwork>
                </v-row>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>


<script>
import { mapActions, mapGetters} from 'vuex'

export default {
     data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters('storeShare', ['platforms', 'share', 'shareState']),
        shareVisible: {
            get(){
                return this.shareState.shareVisible
            },
            set(visible) {
                return this.setShareVisible(visible)
            }
        }
    },
    methods: {
        ...mapActions('storeShare', ['setShare', 'setShareVisible']),
        copyToClipboard() {
            navigator.clipboard.writeText(this.shareState.share.url.length ? this.shareState.share.url : this.shareState.share.description)
            this.shareState.shareVisible = false
        },
    }
}

</script>

<style scoped>
.network-txt {
    font-size: 12px;
}
.v-application a {
    color: transparent;
}

</style>